var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_brand"}},[_vm._v("Brand ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_brand),expression:"form.id_brand"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_brand != '',
                    'is-invalid':
                      _vm.formValidate.id_brand || _vm.form.id_brand == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_brand", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_brand = ''}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listBrand),function(brand){return _c('option',{key:brand.id_brand,domProps:{"value":brand.id_brand}},[_vm._v(" "+_vm._s(brand.nama_brand)+" ")])})],2)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_type"}},[_vm._v("Type ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_type),expression:"form.id_type"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_type != '',
                    'is-invalid': _vm.formValidate.id_type || _vm.form.id_type == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_type = ''}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listType),function(tipe){return _c('option',{key:tipe.id_type,domProps:{"value":tipe.id_type}},[_vm._v(" "+_vm._s(tipe.nama_type)+" ")])})],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_area"}},[_vm._v("Area ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_area),expression:"form.id_area"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_area != '',
                    'is-invalid': _vm.formValidate.id_area || _vm.form.id_area == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_area", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_area = '';
                    _vm.getLokasi();}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listArea),function(area){return _c('option',{key:area.id_area,domProps:{"value":area.id_area}},[_vm._v(" "+_vm._s(area.nama_area)+" ")])})],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_lokasi"}},[_vm._v("Lokasi ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_lokasi),expression:"form.id_lokasi"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_lokasi != '',
                    'is-invalid':
                      _vm.formValidate.id_lokasi || _vm.form.id_lokasi == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_lokasi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_lokasi = '';
                    _vm.getSubLokasi();}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listLokasi),function(lokasi){return _c('option',{key:lokasi.id_lokasi,domProps:{"value":lokasi.id_lokasi}},[_vm._v(" "+_vm._s(lokasi.nama_lokasi)+" ")])})],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_sublokasi"}},[_vm._v("Sub Lokasi ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_sublokasi),expression:"form.id_sublokasi"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_sublokasi != '',
                    'is-invalid':
                      _vm.formValidate.id_sublokasi || _vm.form.id_sublokasi == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_sublokasi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_sublokasi = '';
                    _vm.getDevice();}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listSubLokasi),function(lokasi){return _c('option',{key:lokasi.id_sublokasi,domProps:{"value":lokasi.id_sublokasi}},[_vm._v(" "+_vm._s(lokasi.nama_sublokasi)+" ")])})],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_equipment"}},[_vm._v("Equipment ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_equipment),expression:"form.id_equipment"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_equipment != '',
                    'is-invalid': _vm.formValidate.id_equipment || _vm.form.id_equipment == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_equipment", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_equipment = '';
                    _vm.getLokasi();}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listEquipment),function(equipment){return _c('option',{key:equipment.id_equipment,domProps:{"value":equipment.id_equipment}},[_vm._v(" "+_vm._s(equipment.nama_equipment)+" ")])})],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"nama_device"}},[_vm._v("Nama Device ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_device),expression:"form.nama_device"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.nama_device != '',
                    'is-invalid':
                      _vm.formValidate.nama_device || _vm.form.nama_device == '',
                  },attrs:{"type":"text","placeholder":"Input Nama Device","required":""},domProps:{"value":(_vm.form.nama_device)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_device", $event.target.value)},function($event){_vm.formValidate.nama_device = ''}]}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"sn_device"}},[_vm._v("SN Device ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sn_device),expression:"form.sn_device"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.sn_device != '',
                    'is-invalid':
                      _vm.formValidate.sn_device || _vm.form.sn_device == '',
                  },attrs:{"type":"text","placeholder":"Input SN Device","required":""},domProps:{"value":(_vm.form.sn_device)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sn_device", $event.target.value)},function($event){_vm.formValidate.sn_device = ''}]}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"ip_device"}},[_vm._v("IP Address Device")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.ip_device),expression:"form.ip_device"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.ip_device != '',
                    'is-invalid':
                      _vm.formValidate.ip_device || _vm.form.ip_device == '',
                  },attrs:{"type":"text","placeholder":"Input IP Address Device","required":""},domProps:{"value":(_vm.form.ip_device)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "ip_device", $event.target.value)},function($event){_vm.formValidate.ip_device = ''}]}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"username_device"}},[_vm._v("Username Device")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username_device),expression:"form.username_device"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.username_device != '',
                    'is-invalid':
                      _vm.formValidate.username_device ||
                      _vm.form.username_device == '',
                  },attrs:{"type":"text","placeholder":"Input Username Device","required":""},domProps:{"value":(_vm.form.username_device)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "username_device", $event.target.value)},function($event){_vm.formValidate.username_device = ''}]}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"password_device"}},[_vm._v("Password Device")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_device),expression:"form.password_device"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.password_device != '',
                    'is-invalid':
                      _vm.formValidate.password_device ||
                      _vm.form.password_device == '',
                  },attrs:{"type":"text","placeholder":"Input Password Device","required":""},domProps:{"value":(_vm.form.password_device)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password_device", $event.target.value)},function($event){_vm.formValidate.password_device = ''}]}})])])])]),_c('div',{staticClass:"panel-footer"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-success float-md-right",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.postData}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Submit ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form Device")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 mb-2"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])
}]

export { render, staticRenderFns }